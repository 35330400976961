/* eslint-disable */
import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { formatDotFullTime, formatDotDate } from "../utils/formatAudioTime";
import { GA } from "../utils/Env";
import "./ShoppingGroupDialog.scss";

const ShoppingGroupDialog = (props) => {
  console.log("ShoppingGroupDialog: ", props);
  // groupPayState 为 'group', 'joinGroup', 'payRemaining'
  const {
    selectLauncherMoney,
    selectMinMoney,
    selectActivityId,
    selectName,
    coursePayMode,
    groupPayState,
    buyEndTime,
    groupMaxCount,
    remainingShoppingGroupCount,
    cancelClickAction,
    afterChoosePayMode,
    bonus,
    selectActivityIndex,
    subStudyPackageActivities,
    shoppingRuleStatus,
    ShoppingGroupBonus,
    saleMode,
    isCourseUser,
    systemTime,
  } = props;
  console.log("ShoppingGroupDialog_selectActivityIndex: ", selectActivityIndex);

  const newGroup = useCallback(() => {
    afterChoosePayMode(
      calcDiscountPercentPrice(selectLauncherMoney),
      "new_groupon",
      selectActivityId,
      selectName,
      coursePayMode,
      selectActivityIndex
    );
    cancelClickAction();
    GA("Courses", "newGroupBuy", selectActivityId);
  }, []);

  const joinGroup = useCallback(() => {
    if (isJoinGroup()) {
      afterChoosePayMode(
        calcDiscountPercentPrice(selectMinMoney),
        "join_groupon",
        selectActivityId,
        selectName,
        coursePayMode,
        selectActivityIndex
      );
      cancelClickAction();
      GA("Courses", "joinGroupBuy", selectActivityId);
    } else {
      console.log("disable join group");
    }
  }, []);

  const isJoinGroup = () => {
    return groupPayState === "join_groupon";
  };

  Date.prototype.addDays = function (days) {
    this.setDate(this.getDate() + days);
    return this;
  };
  const addEightDays = new Date(buyEndTime).addDays(8);

  // 计算课时按比例抵扣价格
  const calcDiscountPercentPrice = (price) => {
    if (selectActivityIndex < 0) return "";
    const current_owned_lesson_count =
      subStudyPackageActivities[selectActivityIndex].owned_media_lesson_count;

    const current_lessons_count =
      subStudyPackageActivities[selectActivityIndex].media_lessons_count;

    const base_price =
      subStudyPackageActivities[selectActivityIndex].base_price;

    let discountPercentPrice;
    if (current_owned_lesson_count > 0) {
      discountPercentPrice = Math.round(
        (1 - current_owned_lesson_count / current_lessons_count) * (price - base_price) + base_price
      );
    } else {
      discountPercentPrice = price;
    }
    console.log("Dialog_discountPercentPrice: ", discountPercentPrice);
    return discountPercentPrice;
  };

  const symbol_mark =
    shoppingRuleStatus != true && saleMode != "shopping_group_bargain"
      ? // &&
        // saleMode != "shopping_group_new_member_only"
        "。"
      : "；";

  const condition_judge =
    ShoppingGroupBonus <= 0 && saleMode != "shopping_group_bargain"
      ? // &&
        // saleMode != "shopping_group_new_member_only"
        "。"
      : "；";

  let shoppingRuleStatusText = "";
  // 拼团裂变
  // if (saleMode == "shopping_group_new_member_only") {
  //   shoppingRuleStatusText = {
  //     text: "新老用户均可开团，仅限新用户参团。",
  //   };
  // }

  if (saleMode == "shopping_group_bargain") {
    if (shoppingRuleStatus == true) {
      shoppingRuleStatusText = {
        text: "开始拼团后，之前的邀请砍价将自动结束。",
      };
    } else {
      shoppingRuleStatusText = {
        text: "开始拼团后，不再享受邀请砍价优惠。",
      };
    }
  }

  const group_rule_introduce = [
    {
      text: "请自行邀请朋友成团，系统不提供拼团帮助；",
    },
    saleMode == "shopping_group_new_member_only"
      ? {
          text:
            new Date(buyEndTime) - new Date(systemTime) >= 86400000
              ? `拼团有效期：24小时；`
              : `拼团有效期截止至：${formatDotFullTime(
                  new Date(buyEndTime)
                )}；`,
        }
      : {
          text: `拼团截止时间：${formatDotFullTime(new Date(buyEndTime))}；`,
        },
    {
      text: `逾期未成团，系统将自动原路退款${condition_judge}`,
    },
    ShoppingGroupBonus > 0
      ? {
          text: `团长的成团奖励，将在${formatDotDate(
            addEightDays
          )} 20:00发送${symbol_mark}`,
        }
      : "",
    shoppingRuleStatusText,
  ];

  const LiBox = [
    {
      title: "加入拼团",
      li_ele: (
        <li key={2}>
          <div
            className={`group-cell ${!isCourseUser ? "join" : "disable"}`}
            onClick={!isCourseUser ? joinGroup : null}
          >
            <div className="cell-left">
              <div className="cell-top-box">
                <h3>加入拼团</h3>
                <div className="cell-right">
                  <div className="price">
                    {`¥${calcDiscountPercentPrice(selectMinMoney)}`}
                  </div>
                </div>
              </div>
              {isCourseUser ? (
                <p>该团仅限新用户参加，您可以发起拼团哦~</p>
              ) : (
                remainingShoppingGroupCount > 0 && (
                  <p>{`还差${remainingShoppingGroupCount}人`}</p>
                )
              )}
            </div>
          </div>
        </li>
      ),
    },
    {
      title: "新开拼团",
      li_ele: (
        <li key={3}>
          <div
            className={`group-cell ${!isCourseUser ? "fission-new" : "join"}`}
            onClick={newGroup}
          >
            <div className="cell-left">
              <div className="cell-top-box">
                <h3>新开拼团</h3>
                <div className="cell-right">
                  <div className="price">
                    {`¥${calcDiscountPercentPrice(selectLauncherMoney)}`}
                  </div>
                </div>
              </div>
              <p>
                {`需要再邀请${groupMaxCount - 1}人拼团${
                  ShoppingGroupBonus > 0
                    ? `（成团后可得${ShoppingGroupBonus}元现金返还）`
                    : ""
                }`}
                {/* {`${groupMaxCount}人成团，成团后团长可获得价值${"98"}元的《${"速度闯关练习册"}》一套`} */}
              </p>
            </div>
          </div>
        </li>
      ),
    },
  ];

  const groupLiBox = isCourseUser ? LiBox.reverse() : LiBox;

  // console.log("groupLiBox: ", groupLiBox);

  return (
    <div className="comp-shopping-group-dialog">
      <div className="dialog-container">
        <div className="close_btn_right" onClick={cancelClickAction}>
          &times;
        </div>
        <ul>
          {isJoinGroup() ? (
            saleMode != "shopping_group_new_member_only" ? (
              <li key={2}>
                <div
                  className={`group-cell ${
                    isJoinGroup() ? "join" : " disable"
                  }`}
                  onClick={joinGroup}
                >
                  <div className="cell-left">
                    <div className="cell-top-box">
                      <h3>加入拼团</h3>
                      <div className="cell-right">
                        <div className="price">
                          {`¥${calcDiscountPercentPrice(selectMinMoney)}`}
                        </div>
                      </div>
                    </div>
                    {remainingShoppingGroupCount > 0 && (
                      <p>{`还差${remainingShoppingGroupCount}人`}</p>
                    )}
                  </div>
                </div>
              </li>
            ) : (
              // 新增拼团裂变代码
              groupLiBox.map((item) => item.li_ele)
            )
          ) : (
            <li key={1}>
              <div className="group-cell new" onClick={newGroup}>
                <div className="cell-left">
                  <div className="cell-top-box">
                    <h3>新开拼团</h3>
                    <div className="cell-right">
                      <div className="price">
                        {`¥${calcDiscountPercentPrice(selectLauncherMoney)}`}
                      </div>
                    </div>
                  </div>
                  {
                    // saleMode == "shopping_group_new_member_only" ? (
                    //   <p>
                    //     {`${groupMaxCount}人成团，成团后团长可获得价值${"98"}元的《${"速度闯关练习册"}》一套`}
                    //   </p>
                    // ) : (
                    <p>
                      {`需要再邀请${groupMaxCount - 1}人拼团${
                        ShoppingGroupBonus > 0
                          ? `（成团后可得${ShoppingGroupBonus}元现金返还）`
                          : ""
                      }`}
                    </p>
                    // )
                  }
                </div>
              </div>
            </li>
          )}
        </ul>
        <div className="group-bottom">
          <ol>
            {group_rule_introduce
              .filter((item) => item != "")
              .map((item, index) => (
                <li key={index}>{`${index + 1}. ${item.text}`}</li>
              ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default ShoppingGroupDialog;
