import React from "react";
import CountDownTime from "../../utils/CountDown";
import "./CouponTicketCouponDown.scss";

export default (props) => {
  const { price, endTime } = props;
  const handleCountdownEnd = (e) => {};
  const showPayRemainingTime = () => {
    return (
      <div className="coupon_remaining_endtime">
        <CountDownTime
          endTime={new Date(endTime)}
          overText="优惠券已过期"
          onEnd={handleCountdownEnd}
        >
          {({ d, h, m, s }) => {
            return (
              <span>
                {`${d == "00" ? "" : `${d}天`}`}
                {h}:{m}:{s}
              </span>
            );
          }}
        </CountDownTime>
      </div>
    );
  };
  return (
    <div className="coupon_ticket_count_down">
      <div className="ticket_count_down_box">
        <div className="coupon_price">
          <span>￥</span>
          {price}
        </div>
        <div className="coupon_count_down">
          <div className="text">距优惠券过期</div>
          {showPayRemainingTime()}
        </div>
      </div>
    </div>
  );
};
