// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../data/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../data/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/sync_training_camp/icon_close_white.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".comp_activity_coupon_ticket{position:fixed;top:0;left:0;bottom:0;right:0;background:rgba(0,0,0,.8);z-index:100}.comp_activity_coupon_ticket .activity_coupon_ticket_box{position:absolute;top:45%;left:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.comp_activity_coupon_ticket .activity_coupon_ticket_box .coupon_text_describe{color:#fff;text-align:center;font-size:16px;font-weight:700;margin-bottom:-10px;white-space:pre-wrap}.comp_activity_coupon_ticket .activity_coupon_ticket_box .comp_coupon_ticket .coupon_ticket_close{position:absolute;right:25px;left:50%;margin-left:-17px;bottom:-50px;width:34px;height:32px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:32.5px 32px;background-position:0 0;background-repeat:no-repeat}.comp_activity_coupon_ticket .activity_coupon_ticket_box .comp_coupon_ticket .draw_success_text img{min-width:24px;width:24px;display:block;margin:0 auto}.comp_activity_coupon_ticket .activity_coupon_ticket_box .comp_coupon_ticket .draw_success_text .text{color:#fff;font-size:12px}", ""]);
// Exports
module.exports = exports;
