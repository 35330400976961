/* eslint-disable react/prop-types */
import React, { memo } from "react";
import RightFloatIcons from "../RightFloatIcons";

export const ActivityRightFloatIcons = memo(
  ({
    isShowComment,
    onCommentClick,
    bonus,
    onRedMoneyClick,
    bottomPosition,
    isContinueUser,
    onContinueUserClick,
    onConsultantClick,
  }) => {
    return (
      <RightFloatIcons
        bottomPosition={bottomPosition}
        // isShowComment={isShowComment}
        // onCommentClick={onCommentClick}
        isShowRedMoney={!!bonus}
        onRedMoneyClick={onRedMoneyClick}
        isShowConsultant
        isContinueUser={isContinueUser}
        onContinueUserClick={onContinueUserClick}
        onConsultantClick={() => {
          if (onConsultantClick) {
            onConsultantClick();
          } else {
            window.location.href =
              "/course_consultants?scenario=22&ref=study_package_activity_details";
          }
        }}
      />
    );
  }
);
