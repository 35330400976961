/* eslint-disable */
import React from "react";
import { GA } from "../utils/Env";
import { ToptipsManager } from "../utils/Toptips";
import ModalManager from "../utils/Modal/ModalManage.jsx";
import StudyPackageActivity from "./StudyPackageActivity";
import { Dialog, DialogManager } from "../utils/Dialog";
import { WechatFollow } from "./WechatFollow";
import CountDownTime from "../utils/CountDown";
import DiscountCountDown from "./new_shopping/DiscountCountDown";
import { debounce } from "../utils/helpers";
import { showPresentCourseDiscount } from "../study_package_activities/StudyPackageActivityTemplate";
import { formatDotDate } from "../utils/formatAudioTime";

import "./CourseLists.scss";

const OlympiadDiscountTipsDialog = ({ ...props }) => {
  console.log("OlympiadDiscountTipsDialog_props: ", props);
  const { subPackageActivities, onClose } = props;

  const {
    discount,
    product_code,
    media_lessons_count,
    owned_media_lesson_count,
  } = props.currentActivity;

  const handleNextCourseTrigger = () => {
    const target_id = discount?.next_phase?.id;
    const len = subPackageActivities.length;
    for (let i = 0; i < len; i++) {
      const element = subPackageActivities[i];
      if (target_id == element.id) {
        $($("#course_list_container_ul li[data-index]")[i]).trigger("click");
      }
    }
    onClose();
  };

  return (
    <div className="olympiad_discount_tips_box">
      <div className="olympiad_discount_tips">
        <div className="olympiad_content">
          <p>
            {`${product_code}课程是《${discount.discount_target_product_name}》的一部分，大部分重复（${media_lessons_count}节课中的${owned_media_lesson_count}节），不建议重复报名。`}
          </p>
          <div className="olympiad_suggest">
            建议你报名<span>{`《${discount?.next_phase?.name}》`}</span>。
          </div>
        </div>
        <div className="olympiad_buttons">
          <div
            className="button_left"
            onClick={debounce(handleNextCourseTrigger)}
          >
            好的
          </div>
          <div className="button_right_close" onClick={onClose}>
            明白，但还是想报
          </div>
        </div>
      </div>
    </div>
  );
};

// 多课程体系（子课程包分级购买）
// 注「重要」：价格的最终 购买，参数间的传递，需要通过<CourseCoupons />中的payClickAction()方法进行传递, 通过<BaseStudyPackageActivity />中的 payClickAction={}传递Buy参数
export default class CourseLists extends React.Component {
  constructor(props) {
    super(props);
    let defa =
      props.subStudyPackageActivities[
        props.selectIndexCourse != -1 && props.selectIndexCourse
          ? props.selectIndexCourse
          : 0
      ];
    defa.index = -1;
    defa.target_id = defa.id;

    console.log("CourseLists_props", props);

    let defaIdx;
    if (defa) {
      defaIdx = defa.index;
    } else {
      defaIdx = -1;
    }

    this.state = {
      isChooseDisplay: "none",
      selectIndex: defaIdx, // 默认选中
      selectActivityId: defa ? defa.target_id : null,
      selectListMoney: 0,
      selectMaxMoney: (defa && defa.max_price) || 0,
      selectMinMoney: (defa && defa.min_price) || 0,
      selectLauncherMoney: (defa && defa.launcher_price) || 0,
      selectName: (defa && defa.name) || "",
      activity: defa,
      activeHighLight:
        props.subStudyPackageActivities.length >= 1 ? false : true,
    };
    console.log("overallBought：" + this.props.overallBought);
    console.log("acitivity_defa: ", defa);
    console.log("activity_type: " + StudyPackageActivity.activityType.group);
    // console.log('activity_value: ' + (StudyPackageActivity.activityType.group === this.state.activity.activity_type))
  }

  // componentDidMount() {
  //   console.log("-----------------------------------------------");
  //   if (this.state.activity.stage == 4) {
  //     let discountPercentPrice =
  //       this.calcDiscountPercentPrice(
  //         this.props.selectMaxMoney > 0
  //           ? this.props.selectMaxMoney
  //           : this.state.selectMaxMoney
  //       ) - (singleAlone ? 0 : canUsedHighestPrice);
  //     let singleAlone =
  //       this.state.activity &&
  //       (this.state.activity.activity_type ===
  //         StudyPackageActivity.activityType.group ||
  //         this.state.activity.sale_mode == "normal" ||
  //         discountPercentPrice == 0);

  //     let max_price =
  //       this.state.activity.stage == 4
  //         ? this.state.activity.max_price
  //         : discountPercentPrice;

  //     this.aloneSignClick(
  //       max_price > 0 ? max_price : 0,
  //       this.props.selectActivityId
  //         ? this.props.selectActivityId
  //         : this.state.selectActivityId,
  //       this.state.selectName,
  //       this.state.selectIndex
  //     );
  //   }
  // }

  setSelectedActivity = (isOneCoinLearningOneWeek) => {
    let defa = this.props.subStudyPackageActivities[0];
    // // 初始化
    // this.setState({
    //   selectIndex: -1,
    //   selectActivityId: null,
    //   selectMaxMoney: 0,
    //   selectMinMoney: 0,
    //   selectLauncherMoney: 0,
    //   selectName: "",
    //   activity: defa,
    //   selectIndexCourse: -1,
    // });

    defa.index = -1;
    defa.target_id = defa.id;
    if (
      this.props.subStudyPackageActivities.length > 1 &&
      this.props.subCourseType === "thinking"
    ) {
      let getCanBoughtState = () => {};
      if (isOneCoinLearningOneWeek) {
        getCanBoughtState = (activity) =>
          activity.trial_order_state === "ok" && activity.state === "ok";
      } else {
        getCanBoughtState = (activity) => activity.state === "ok";
      }
      // 是否有推荐课程
      const index = this.props.subStudyPackageActivities.findIndex(
        (activity) => {
          if (activity.is_recommended && getCanBoughtState(activity)) {
            return activity;
          }
        }
      );
      if (index != -1) {
        defa = this.props.subStudyPackageActivities[index];
        defa.index = index;
        defa.target_id = defa.id;
      } else {
        // 找到一个可以购买的
        const canButAct = this.props.subStudyPackageActivities.find(
          (activity, index) => {
            if (getCanBoughtState(activity)) {
              activity["target_id"] = activity.id;
              // activity["index"] = index;
              activity["index"] = index;
              return activity;
            }
          }
        );
        if (canButAct) defa = canButAct;
      }
    }
    let defaIdx;
    if (defa) {
      defaIdx = defa.index;
    } else {
      defaIdx = -1;
    }

    this.setState({
      selectIndex: defaIdx, // 默认选中
      selectActivityId: defa ? defa.target_id : null,
      selectMaxMoney: (defa && defa.max_price) || 0,
      selectMinMoney: (defa && defa.min_price) || 0,
      selectLauncherMoney: (defa && defa.launcher_price) || 0,
      selectName: (defa && defa.name) || "",
      activity: defa,
      preventCallPropsSetState: true,
    });
    if (defaIdx !== -1) {
      const activityList = Array.from(
        document.getElementById("course_list_container_ul")?.childNodes ?? []
      );
      if (activityList.length > 0) {
        if (activityList[defaIdx]) {
          // eslint-disable-next-line no-unused-expressions
          $(activityList[defaIdx])?.trigger?.("click");
        }
      }
    }
  };

  btnCloseRecommendListClick = () => {
    this.setState({
      isChooseDisplay: "none",
    });

    if (this.props.getIsExperienceStatus) {
      this.props.getIsExperienceStatus(false);
    }
  };

  // 计算应用奥数课提示·弹框
  handleOlympiadDiscountTips = (activity) => {
    return new ModalManager({
      render: (_, modalClose) => {
        const onClose = () => {
          modalClose();
        };
        return (
          <OlympiadDiscountTipsDialog
            currentActivity={activity}
            subPackageActivities={this.props.subStudyPackageActivities}
            onClose={onClose}
          />
        );
      },
    });
  };

  selectClick = (activity, e) => {
    let nextBoughtActivityName;
    if (activity.bought_activities) {
      nextBoughtActivityName = activity.bought_activities.reduce(
        (acc, cur, index) => {
          if (index === activity.bought_activities.length - 1) {
            return acc + `《${cur.name}》`;
          }
          return acc + `《${cur.name}》、`;
        },
        ""
      );
    }
    if (activity.state == "partial") {
      const buttons = [
        {
          label: "我知道了",
          onClick: () => confirmDialog.close(),
        },
      ];
      const confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          {`您已拥有${nextBoughtActivityName}课程`}
        </Dialog>
      );
      return;
    }

    const index = e.currentTarget.getAttribute("data-index");
    const activity_id = e.currentTarget.getAttribute("data-id");
    const activity_state = e.currentTarget.getAttribute("data-state");
    const list_value = e.currentTarget.getAttribute("data-list-value");
    const max_value = e.currentTarget.getAttribute("data-max-value");
    const min_value = e.currentTarget.getAttribute("data-min-value");
    const select_name = e.currentTarget.getAttribute("data-name");
    const launcher_value = e.currentTarget.getAttribute("data-launcher-value");

    this.setState(
      {
        selectIndex: index,
        selectActivityId: activity_id,
        selectActivityState: activity_state,
        selectListMoney: list_value,
        selectMaxMoney: max_value,
        selectMinMoney: min_value,
        selectLauncherMoney: launcher_value,
        selectName: select_name,
        activity: activity,
        activeHighLight: true,
        selectActivityRedirect: activity.redirect_url
          ? activity.redirect_url
          : false,
      },
      () => {}
    );
    if (activity.redirect_url) {
      window.location.href = activity.redirect_url;
    }
    if (activity.discount && !activity.redirect_url) {
      this.handleOlympiadDiscountTips(activity).open();
      GA("Courses", "popDiscounts", this.props.studyPackageActivityId);
    }

    // if (typeof this.props.selectSubClick === "function") {
    //   this.props.selectSubClick(e);
    // }

    const { selectActivity } = this.props;
    if (typeof selectActivity === "function" && !activity.redirect_url) {
      selectActivity(index, activity);
    }
  };

  // 普通模式 / 邀请模式 支付
  btnPayRequest = (
    selectMaxMoney,
    activityTargetId,
    selectName,
    selectIndex,
    trial
  ) => {
    console.log("普通模式 / 邀请模式_selectIndex: ", selectIndex);
    if (this.state.selectIndex === -1) {
      ToptipsManager.warn("请选择需要购买的课程");
    } else {
      this.props.preOnDirectBuy(
        selectMaxMoney,
        activityTargetId,
        selectName,
        selectIndex,
        trial
      );
      this.setState({
        isChooseDisplay: "none",
      });
    }
  };

  // （拼团模式）单独报名
  aloneSignClick = (
    selectMaxMoney,
    activityTargetId,
    selectName,
    selectIndex
  ) => {
    if (this.props.qrcodeUrl) {
      this.onWechatFollow();
      return;
    }
    // console.log("aloneSignClick", selectIndex);
    if (this.state.selectIndex === -1) {
      ToptipsManager.warn("请选择需要购买的课程");
      return;
    } else {
      this.props.preOnDirectBuy(
        selectMaxMoney,
        activityTargetId,
        selectName,
        selectIndex
      );
      this.setState({
        isChooseDisplay: "none",
      });
      GA("Courses", "directBuy", activityTargetId);
    }
  };

  // // （拼团模式）拼团报名
  // groupSignClick = (selectMinMoney, activityTargetId, selectName) => {
  //   if (this.state.selectIndex === -1) {
  //     ToptipsManager.warn("请选择需要购买的课程")
  //     return
  //   } else {
  //     if (this.props.shoppingGroup && ('id' in this.props.shoppingGroup)) {
  //       this.props.preOnGroupBuy(selectMinMoney, 'join_groupon', activityTargetId, selectName)
  //       GA('Courses', 'joinGroupBuy', activityTargetId)
  //     } else {
  //       this.props.preOnGroupBuy(selectMinMoney, 'new_groupon', activityTargetId, selectName)
  //
  //       GA('Courses', 'newGroupBuy', activityTargetId)
  //     }
  //   }
  // }

  // （拼团模式）拼团报名
  groupBuyClick = (selectIndex, activity, shoppingRule) => {
    console.log("groupBuyClick selectIndex: ", selectIndex);
    const {
      preGroupBuy,
      shoppingGroup,
      currentUser,
      remainingShoppingGroupCount,
      overallBought,
      bought,
    } = this.props;
    if (selectIndex === -1) {
      ToptipsManager.warn("请选择需要购买的课程");
    } else {
      if (typeof preGroupBuy !== "function") {
        return;
      }
      if (
        currentUser &&
        shoppingGroup &&
        "user_shopping_groups" in shoppingGroup
      ) {
        let hadJoinGroupon = shoppingGroup.user_shopping_groups
          .map((usg) => {
            if (usg.user) {
              return usg.user.id;
            }
          })
          .filter((id) => {
            return id;
          })
          .includes(currentUser.id);
        if (!hadJoinGroupon && remainingShoppingGroupCount > 0) {
          console.log("可以加入拼团index: ", selectIndex);
          // 可以加入拼团
          preGroupBuy(selectIndex, "join_groupon", activity, shoppingRule);
          GA("Courses", "chooseGroup", activity.id);
          return;
        }
      }
      // 新开团
      console.log("新开团index: ", selectIndex);
      console.log("shoppingRule: ", shoppingRule);
      preGroupBuy(selectIndex, "new_groupon", activity, shoppingRule);
      GA("Courses", "chooseGroup", activity.id);
    }
  };

  //(砍价模式) 邀请报名
  subBargainSignClick = (
    selectMinMoney,
    selectMaxMoney,
    activityTargetId,
    selectName
  ) => {
    console.log(
      "subBargainSignClick_",
      "selectMinMoney: ",
      selectMinMoney,
      "selectMaxMoney: ",
      selectMaxMoney,
      "activityTargetId: ",
      activityTargetId,
      "selectName: ",
      selectName
    );
    if (this.state.selectIndex === -1) {
      ToptipsManager.warn("请选择需要购买的课程");
      return;
    } else {
      this.props.directBuyCourse(
        selectMinMoney,
        selectMaxMoney,
        activityTargetId,
        selectName
      );
      this.setState({
        isChooseDisplay: "none",
      });
    }
  };

  presentCourseDiscountDialog = (
    selectMaxMoney,
    finalState,
    selectActivityId,
    selectName,
    coursePayMode,
    selectIndex,
    trial
  ) => {
    if (this.props.qrcodeUrl) {
      this.onWechatFollow();
      return;
    }

    showPresentCourseDiscount(
      this.props.afterChoosePayMode,
      selectMaxMoney,
      finalState,
      selectActivityId,
      selectName,
      coursePayMode,
      selectIndex,
      trial
    );
  };

  handleCountdownEnd = (e) => {};

  subInviteUser = (activity) => {
    this.props.invitingUser();
  };

  finalContent = (
    activity,
    saleMode,
    invitedUsersCount,
    inviteRewardPrice,
    subMapIndex,
    subStudyPackageActivitiesLength
  ) => {
    console.log(
      "finalContent_: ",
      activity,
      saleMode,
      invitedUsersCount,
      inviteRewardPrice,
      subMapIndex
    );
    var normal =
      !activity.min_price ||
      activity.min_price < 0 ||
      activity.min_price == activity.max_price
        ? "¥" + activity.max_price
        : "¥" + activity.min_price + "起";
    if (
      StudyPackageActivity.specialCategory.presells.includes(
        activity.study_package_category.id
      )
    ) {
      console.log("111-111");
      var shoppingGroup = (
        <div className="bird_price">
          <span>
            早鸟价：<i>{`¥${activity.max_price}`}</i>
          </span>
        </div>
      );
    } else {
      console.log("222-222");
      var shoppingGroup =
        (activity.state === "ok" || activity.state == "partial") &&
        (activity.activity_type == 1 && activity.sale_mode !== "shopping_group"
          ? `免拼团价：¥${activity.max_price}`
          : !activity.min_price ||
            activity.min_price < 0 ||
            activity.min_price == activity.max_price
          ? `¥${activity.max_price}`
          : `¥${activity.min_price}起`);
    }

    if (saleMode === "bargain" || saleMode === "buy_bargain") {
      console.log("砍价");
      if (
        activity.max_price - invitedUsersCount * inviteRewardPrice <
        activity.min_price
      ) {
        console.log("333-333");
        var bargain = (
          <p>
            {`¥${this.calcDiscountPercentPrice(activity.min_price)}`}
            <span>
              {this.judgeOwnedMediaLessonCountMoreThanZero(subMapIndex) > 0 && (
                <del>{`¥${activity.max_price}`}</del>
              )}
            </span>
          </p>
        );
      } else if (
        activity.max_price - invitedUsersCount * inviteRewardPrice <
        activity.max_price
      ) {
        console.log("444-444-sspal", subStudyPackageActivitiesLength);
        const { calcSortLastDerateValue } = this.calcBargainDiscount(
          activity,
          subMapIndex
        );
        var bargainPrice =
          subStudyPackageActivitiesLength == 1 &&
          activity.owned_media_lesson_count > 0
            ? Number(calcSortLastDerateValue)
            : 0;
        const judgeDiscountPrice =
          activity.max_price - invitedUsersCount * inviteRewardPrice;

        console.log(
          "calcSortLastDerateValue/bargainPrice/judgeDiscountPrice: ",
          calcSortLastDerateValue,
          bargainPrice,
          judgeDiscountPrice
        );

        let listRightPrice;
        if (this.props.subStudyPackageActivities.length > 1) {
          listRightPrice = this.calcDiscountPercentPrice(judgeDiscountPrice);
        } else {
          listRightPrice =
            judgeDiscountPrice - bargainPrice > 0
              ? judgeDiscountPrice - bargainPrice
              : 0;
        }

        var bargain = (
          <p>
            {`¥${listRightPrice}`}
            <span>
              {this.judgeOwnedMediaLessonCountMoreThanZero(subMapIndex) > 0 && (
                <del>{`¥${activity.max_price}`}</del>
              )}
            </span>
          </p>
        );
      } else {
        console.log(
          "555-555-subMapIndex",
          this.judgeOwnedMediaLessonCountMoreThanZero(subMapIndex)
        );

        var bargain = (
          <p>
            {`¥${this.calcDiscountPercentPrice(
              activity.max_price - invitedUsersCount * inviteRewardPrice,
              subMapIndex
            )}`}
            <span>
              {this.judgeOwnedMediaLessonCountMoreThanZero(subMapIndex) > 0 && (
                <del>
                  {"¥" +
                    (activity.max_price -
                      invitedUsersCount * inviteRewardPrice)}
                </del>
              )}
            </span>
          </p>
        );
      }
    }

    if (saleMode === "invite") {
      console.log(
        "666-邀请subMapIndex",
        this.judgeOwnedMediaLessonCountMoreThanZero(subMapIndex)
      );
      var invite = (
        <p>
          {`¥${this.calcDiscountPercentPrice(activity.max_price, subMapIndex)}`}
          <span>
            {this.judgeOwnedMediaLessonCountMoreThanZero(subMapIndex) > 0 && (
              <del>{`¥${activity.max_price}`}</del>
            )}
          </span>
        </p>
      );
    }

    if (saleMode === "invitation_fission") {
      console.log("777--邀请裂变_invitation_fission");
      var inviteFission = `¥${activity.max_price}`;
    }

    var defaultContent = <span>{`¥${activity.min_price}`}</span>;
    switch (saleMode) {
      case "normal":
        return normal;
        break;
      case "shopping_group":
      case "shopping_group_bargain":
      case "shopping_group_new_member_only":
        return shoppingGroup;
        break;
      case "invitation_fission":
        return inviteFission;
        break;
      case "bargain":
      case "buy_bargain":
        return bargain;
        break;
      case "invite":
        return invite;
        break;
      default:
        return defaultContent;
        break;
    }
  };

  priceContent = (
    activity,
    inviteRewardPrice,
    subMapIndex,
    subStudyPackageActivitiesLength
  ) => {
    console.log("props_priceContent", activity);
    let content = "";
    if (activity.remaining_count == 0) {
      content = (
        <div className="content_right">
          <div className="sign_success">已报满</div>
        </div>
      );
      return content;
    }

    if (
      activity.state == "pre_has_bought" ||
      activity.state == "superset" ||
      activity.state == "bought" ||
      (this.state.isOneCoinLearningOneWeek &&
        activity.trial_order_state === "bought")
    ) {
      content = (
        <div className="content_right">
          <div className="sign_success">已报名</div>
        </div>
      );
      return content;
    }

    const originalPriceLabel = (
      <div className="course_origin_price">
        {activity.list_price > 0 ? (
          <p>
            <span>
              原价：<del>¥{activity.list_price}</del>
            </span>
          </p>
        ) : (
          ""
        )}
      </div>
    );

    // 标价
    var originalPrice =
      StudyPackageActivity.specialCategory.presells.includes(
        activity.study_package_category.id
      ) || activity.activity_type === StudyPackageActivity.activityType.group
        ? originalPriceLabel
        : "";
    // 一口价
    var price = (
      <div className="course_max_price" style={{ fontWeight: "bold" }}>
        {this.finalContent(
          activity,
          this.props.saleMode,
          this.props.invitedUsersCount,
          inviteRewardPrice,
          subMapIndex,
          subStudyPackageActivitiesLength
        )}
      </div>
    );

    // 抵扣价 based on owned_media_lesson_count
    let priceBasedOnOwnedMediaLessonCount = 0;
    // 系列课
    if (
      this.props.subStudyPackageActivities &&
      this.props.subStudyPackageActivities.length >= 2
    ) {
      priceBasedOnOwnedMediaLessonCount = this.calcDiscountPercentPrice(
        activity.min_price,
        subMapIndex
      );
    } else {
      priceBasedOnOwnedMediaLessonCount = this.calcDiscountPercentPrice(
        activity.min_price,
        subMapIndex
      );
    }
    console.log(
      "priceBasedOnOwnedMediaLessonCount: ",
      priceBasedOnOwnedMediaLessonCount
    );

    if (priceBasedOnOwnedMediaLessonCount < activity.min_price) {
      content = (
        <div className="content_right">
          <div
            className="course_max_price "
            style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
          >
            {priceBasedOnOwnedMediaLessonCount == 0
              ? "抵扣价：￥0 起"
              : `抵扣价：￥${priceBasedOnOwnedMediaLessonCount}  起`}
          </div>
          {originalPriceLabel}
        </div>
      );
    } else {
      content = (
        <div className="content_right">
          {price}
          {originalPrice}
        </div>
      );
    }
    return content;
  };

  renderContent = (
    activity,
    inviteRewardPrice,
    subMapIndex,
    subStudyPackageActivitiesLength,
    subCourseType
  ) => {
    const nickname = this.props.baby?.nickname;
    console.log("nickname: ", this.props.baby, nickname);
    const isBought =
      activity.state == "pre_has_bought" ||
      activity.state == "superset" ||
      activity.state == "bought";
    return (
      <a href="javascript:void(0)">
        <div className="content_left">
          <div className="course_title">{activity.name}</div>
          {activity.batch_ecoupons_amount && subCourseType !== "reading" ? (
            <div className="course_desc">
              含各个级别课程试听 ，完成任意一天试听，即可领取
              <span
                style={{
                  color: "#e86d74",
                }}
              >
                {activity.batch_ecoupons_amount}元
              </span>
              优惠券（限领1次）
            </div>
          ) : (
            activity.brief && (
              <div className="course_desc">{activity.brief}</div>
            )
          )}
          {((!this.props.isThinkingSeries && activity.is_recommended) ||
            (this.props.isThinkingSeries &&
              !isBought &&
              activity.is_recommended)) && (
            <div className="recommend_choice">
              <span className="span_first">建议选择</span>
              {activity.recommend_reason == 0 ? (
                <span className="span_second">{`基于${
                  nickname
                    ? nickname.length > 5
                      ? `${nickname.substring(0, 5)}...`
                      : nickname
                    : "小宝"
                }的测评结果`}</span>
              ) : activity.recommend_reason == 1 ? (
                <span className="span_second">基于“适合课程”问卷结果</span>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        {this.priceContent(
          activity,
          inviteRewardPrice,
          subMapIndex,
          subStudyPackageActivitiesLength
        )}
      </a>
    );
  };

  grouponModeStatus = () => {
    if (this.props.grouponDirectBuy) {
      return <p>免拼团，直接报名</p>;
    }

    // if (!this.props.shoppingGroup) {
    //   return (
    //     <p>邀请拼团，共需{this.props.groupMaxCount || 3}人</p>
    //   )
    // }

    // if (this.props.remainingShoppingGroupCount === 0) {
    //   return (
    //     <p>加入拼团</p>
    //   )
    // }

    return (
      //<p>加入拼团，还需{this.props.remainingShoppingGroupCount}人</p>
      <p>{this.props.groupMaxCount + "人拼团"}</p>
    );
  };

  findPropOrStateSelectIndex = () => {
    let courseIndex =
      this.props.selectIndexCourse > 0
        ? this.props.selectIndexCourse
        : this.state.selectIndex;
    console.log("findPropOrStateSelectIndex_courseIdx: ", courseIndex);
    return courseIndex;
  };

  judgeBtnBottomIsHide = () => {
    // const activity = this.state.activity;
    return (
      this.props.overallBought == true
      // || this.findPropOrStateSelectIndex() == -1
    );
  };

  // 底部红色按钮区域，计算课时按比例抵扣价格
  calcDiscountPercentPrice = (price, paramIndex) => {
    console.log(
      "653_selectIndexCourse_selectIndex",
      this.props.selectIndexCourse,
      this.state.selectIndex
    );
    console.log("654_price_paramIndex", price, paramIndex);

    let courseIdx =
      paramIndex != null ? paramIndex : this.findPropOrStateSelectIndex();

    console.log("525_courseIdx", courseIdx);

    if (courseIdx < 0) return "";
    const { subStudyPackageActivities } = this.props;
    const current_owned_lesson_count =
      subStudyPackageActivities[courseIdx].owned_media_lesson_count;
    const current_lessons_count =
      subStudyPackageActivities[courseIdx].media_lessons_count;
    const base_price =
      subStudyPackageActivities[courseIdx].base_price;

    let discountPercentPrice;
    if (current_owned_lesson_count > 0) {
      discountPercentPrice = Math.round(
        (1 - current_owned_lesson_count / current_lessons_count) * (price - base_price) + base_price
      );
    } else {
      discountPercentPrice = price;
    }

    console.log("discountPercentPrice: ", discountPercentPrice);
    return discountPercentPrice;
  };

  // 原价和抵扣价的差值集合
  //【注：砍价折扣只针对单课程，即subStudyPackageActivities长度为1的时候】
  calcBargainDiscount = (activity, index) => {
    let loopGetDiscountMinusPrice = [];
    loopGetDiscountMinusPrice.push({
      discountPrice: this.calcDiscountPercentPrice(activity.max_price, index),
      listPrice: activity.max_price,
    });
    let sortDescendingArray = loopGetDiscountMinusPrice.sort(function (a, b) {
      return a.discountPrice - b.discountPrice;
    });
    const lastSubDescendingArray =
      sortDescendingArray[sortDescendingArray.length - 1];
    const calcSortLastDerateValue =
      lastSubDescendingArray.listPrice - lastSubDescendingArray.discountPrice;
    return {
      calcSortLastDerateValue, // 计算可被抵扣的价格（原价 - 抵扣价）
    };
  };

  // 判断分级索引中的owned_media_lesson_count是否需要课时折扣
  judgeOwnedMediaLessonCountMoreThanZero = (subMapIndex) => {
    const { subStudyPackageActivities } = this.props;
    console.log("693_subStudyPackageActivities: ", subStudyPackageActivities);
    const currentLessonCount =
      subStudyPackageActivities[subMapIndex]?.owned_media_lesson_count;
    let mapSubIndexLessonCount;
    if (currentLessonCount > 0) {
      mapSubIndexLessonCount = currentLessonCount;
    } else {
      mapSubIndexLessonCount = 0;
    }
    return mapSubIndexLessonCount;
  };

  // 找到owned_media_lesson_count, 判断是否需要折价
  findOwnedMediaLessonCount = () => {
    let courseIdx = this.findPropOrStateSelectIndex();
    console.log("f543_indOwnedMediaLessonCount_courseIdx: ", courseIdx);

    if (courseIdx < 0) return "";
    const { subStudyPackageActivities } = this.props;
    const is_owned_lesson_count =
      subStudyPackageActivities[courseIdx].owned_media_lesson_count > 0;
    return is_owned_lesson_count;
  };

  groupPrice = () => {
    console.log(
      "找到owned_media_lesson_count, 判断是否需要折价(boolean): ",
      this.findOwnedMediaLessonCount()
    );
    let { selectLauncherMoney, selectMinMoney } = this.props;
    console.log(
      "props_selectLauncherMoney/selectMinMoney: ",
      selectLauncherMoney,
      selectMinMoney
    );
    console.log(
      "state_selectLauncherMoney/selectMinMoney : ",
      this.state.selectLauncherMoney,
      this.state.selectMinMoney
    );

    if (!selectLauncherMoney) {
      console.log("1---1", selectLauncherMoney);
      selectLauncherMoney = this.state.selectLauncherMoney;
    }
    if (!selectMinMoney) {
      console.log("2---1", selectMinMoney);
      selectMinMoney = this.state.selectMinMoney;
    }

    if (this.props.saleMode == "shopping_group_bargain") {
      return (
        <p>
          3人成团
          {`¥${this.calcDiscountPercentPrice(selectLauncherMoney)}`}
        </p>
      );
    }

    if (selectMinMoney === selectLauncherMoney) {
      console.log("3---1", selectMinMoney, selectLauncherMoney);
      return (
        <p>
          {`¥${this.calcDiscountPercentPrice(selectLauncherMoney)}`}
          {this.findOwnedMediaLessonCount() && (
            <del>{`¥${selectLauncherMoney}`}</del>
          )}
        </p>
      );
    }
    if (!this.props.shoppingGroup) {
      console.log("4---1", selectMinMoney, selectLauncherMoney);
      return (
        <p>
          ¥{`${this.calcDiscountPercentPrice(selectLauncherMoney)}`}
          <span>
            {this.findOwnedMediaLessonCount() && (
              <del>¥{`${selectMinMoney}`}</del>
            )}
          </span>
        </p>
      );
    }
    console.log("5---1", selectMinMoney, selectLauncherMoney);
    console.log(
      589,
      `¥${this.calcDiscountPercentPrice(selectLauncherMoney - selectMinMoney)}`
    );
    return (
      <p>
        {`¥${this.calcDiscountPercentPrice(
          selectLauncherMoney - selectMinMoney < 0
            ? selectLauncherMoney
            : selectLauncherMoney - selectMinMoney
        )}`}
        {this.findOwnedMediaLessonCount() && (
          <del>{`¥${selectLauncherMoney} - ${selectMinMoney}`}</del>
        )}
      </p>
    );
  };

  // renderContentTop = (activity, inviteRewardPrice) => {
  //   if (activity.state == "pre_has_bought" || activity.state == "superset" || activity.state == "bought") {
  //     var content = (
  //       <div className="sign_success">
  //         已报名
  //       </div>
  //     )
  //   } else {

  //     var content = (
  //       <div className="course_min_price">
  //             {
  //               this.finalContent(activity, this.props.saleMode, this.props.invitedUsersCount, inviteRewardPrice)
  //               // this.props.saleMode == 'normal'
  //               //   ?
  //               //   ("¥" + activity.max_price)
  //               //   :
  //               //   (
  //               //     this.props.saleMode == 'shopping_group'
  //               //       ?
  //               //       (
  //               //         activity.state === 'ok' && ("¥" + activity.max_price)
  //               //       )
  //               //       :
  //               //       (
  //               //         this.props.saleMode == 'bargain'
  //               //           ?

  //               //           :
  //               //           <span>{"¥" + activity.min_price}</span>
  //               //       )
  //               //   )
  //             }
  //           </div>
  //     )
  //   }

  //   return (
  //       <div className="content_top">
  //       {
  //         content
  //       }
  //       <div className="course_title">{activity.name}</div>
  //     </div>
  //   )
  // }

  // renderContentBottom = (activity) => {
  //   var brief = activity.brief
  //   return (
  //       <div className="content_bottom">
  //         <div className="course_max_price">
  //           {
  //             this.props.saleMode == 'shopping_group'
  //               ?
  //               (
  //                 // activity.state === 'ok' && ("单独报名: ¥" + activity.max_price)
  //                 (
  //                   this.props.grouponDirectBuy === true
  //                     ?
  //                     "免拼团: ¥"
  //                     :
  //                     `${this.props.groupMaxCount + "人团： ¥"}`
  //                 )
  //                 +
  //                 (activity.min_price)
  //               )
  //               :
  //               (
  //                 this.props.saleMode == 'bargain'
  //                   ?
  //                   activity.state === 'ok' &&
  //                   <span>
  //                     可通过邀请减至<i>¥{activity.min_price}</i>
  //                   </span>
  //                   :
  //                   <del>¥{activity.list_price}</del>
  //               )
  //           }
  //         </div>
  //         {
  //           brief &&
  //           <div className="course_desc">{brief}</div>
  //         }
  //     </div>
  //   )
  // }

  // 一元学一周直接报名
  setOneCoinLearningOneWeek = (bool) => {
    this.setState({
      isOneCoinLearningOneWeek: bool,
    });
  };
  onWechatFollow = () => {
    this.wechatFollowRef.showWechatFollow();
  };

  componentDidMount() {
    if (
      $("#course_list_container_ul li[data-index]")?.length > 0 &&
      this.props.subStudyPackageActivities.length == 1
    ) {
      $($("#course_list_container_ul li[data-index]")[0]).trigger("click");
      // $("#course_list_container_ul li[data-index]")
    }
  }

  render() {
    // console.log(
    //   "CourseLists selectIndexCourse: ",
    //   this.props.selectIndexCourse
    // );

    let inviteRewardPrice = this.props.moneyPerUser;
    // console.log("inviteRewardPrice: ", inviteRewardPrice);
    console.log(
      "selectMinMoney_props/state: ",
      this.props.selectMinMoney,
      this.state.selectMinMoney
    );
    console.log(
      "selectMaxMoney_props/state: ",
      this.props.selectMaxMoney,
      this.state.selectMaxMoney
    );
    // console.log("invitedUsersCount: ", this.props.invitedUsersCount);

    let calcPayPrice;
    let finallyPayPrice;
    let finallySignText;
    calcPayPrice =
      this.props.selectMaxMoney > 0 && this.props.selectMinMoney > 0
        ? this.props.selectMaxMoney -
            this.props.invitedUsersCount * inviteRewardPrice <
          this.props.selectMinMoney
          ? this.props.selectMinMoney
          : this.props.selectMaxMoney -
            this.props.invitedUsersCount * inviteRewardPrice
        : this.state.selectMaxMoney -
            this.props.invitedUsersCount * inviteRewardPrice <
          this.state.selectMinMoney
        ? this.state.selectMinMoney
        : this.state.selectMaxMoney -
          this.props.invitedUsersCount * inviteRewardPrice;

    console.log("calcPayPrice: ", calcPayPrice);

    // 计算拼团砍价的折扣金额，变量统一处理
    let shoppingBargainDiscountPrice = 0; // ① 邀请新人得到的钱（邀请N人 * 每人N钱）
    let highestDiscountPrice = 0; // ②：最高可被砍的价格（原价 - 最低价）
    let canUsedHighestPrice = 0; // ③: 可被使用的最高价格（② >= ① ？ ② ：(② - ①)）
    let calcRemaingBargainPrice = 0; // ④: 还可以被砍的价格：② - ①
    let lastRemaingBargainPrice = 0; // ⑤: 同上：最终价

    if (this.props.shoppingBargain) {
      const {
        invited_users_count,
        money_per_user,
        activity,
        max_bargain_amount,
      } = this.props.shoppingBargain;
      shoppingBargainDiscountPrice = invited_users_count * money_per_user;

      highestDiscountPrice = activity.price - activity.lowest_price;
      calcRemaingBargainPrice =
        highestDiscountPrice - shoppingBargainDiscountPrice;

      lastRemaingBargainPrice =
        calcRemaingBargainPrice > 0 ? calcRemaingBargainPrice : 0;

      console.log(
        "CourseList_lastRemaingBargainPrice: ",
        lastRemaingBargainPrice
      );

      canUsedHighestPrice =
        shoppingBargainDiscountPrice >= highestDiscountPrice
          ? highestDiscountPrice
          : shoppingBargainDiscountPrice;
    }
    console.log("canUsedHighestPrice: ", canUsedHighestPrice);

    let bargainDiscountMoney;
    if (this.props.subStudyPackageActivities.length > 1) {
      bargainDiscountMoney = 0;
    } else {
      // 数组仅有一项
      if (
        this.props.subStudyPackageActivities[0]?.owned_media_lesson_count > 0
      ) {
        bargainDiscountMoney = this.calcBargainDiscount(
          this.props.subStudyPackageActivities[0],
          0
        ).calcSortLastDerateValue;
      } else {
        bargainDiscountMoney = 0;
      }
    }
    console.log("bargainDiscountMoney", bargainDiscountMoney);

    if (this.props.saleMode == "invite" || this.props.saleMode === 'subscription_fission') {
      if (this.props.invitedUsersCount < this.props.inviteCountRequired) {
        finallyPayPrice = (
          <p>
            {`¥${this.calcDiscountPercentPrice(this.state.selectMaxMoney)}`}
            {this.findOwnedMediaLessonCount() && (
              <del>{`¥${this.state.selectMaxMoney}`}</del>
            )}
          </p>
        );
        finallySignText = (
          <p style={{ fontSize: !this.state.activeHighLight ? "15px" : "" }}>
            立即报名
          </p>
        );
      } else {
        finallyPayPrice = "";
        finallySignText = (
          <p style={{ fontSize: !this.state.activeHighLight ? "15px" : "" }}>
            免费领取
          </p>
        );
      }
    } else {
      let percentDiscountPrice;

      if (
        this.props.saleMode == "bargain" &&
        this.props.subStudyPackageActivities.length == 1
      ) {
        // this.props.invitedUsersCount * inviteRewardPrice - bargainDiscountMoney
        console.log("----enter--");
        // 仅针对砍价模式且数组集合只有一项的情况
        percentDiscountPrice = calcPayPrice;
      } else {
        percentDiscountPrice = this.calcDiscountPercentPrice(calcPayPrice);
      }
      console.log("percentDiscountPrice: ", percentDiscountPrice);

      let lastDiscountPrice;
      if (this.props.subStudyPackageActivities.length > 1) {
        lastDiscountPrice = this.calcDiscountPercentPrice(calcPayPrice);
      } else {
        lastDiscountPrice =
          percentDiscountPrice - bargainDiscountMoney > 0
            ? percentDiscountPrice - bargainDiscountMoney
            : 0;
      }

      finallyPayPrice = (
        <p>
          {`¥${lastDiscountPrice}`}
          {this.findOwnedMediaLessonCount() && (
            // <del>{`¥${calcPayPrice}`}</del>
            <del>{`¥${this.state.selectMaxMoney}`}</del>
          )}
        </p>
      );
      finallySignText = (
        <p style={{ fontSize: !this.state.activeHighLight ? "15px" : "" }}>
          立即报名
        </p>
      );
    }

    // 邀请按钮
    let inviteButtonText = null;
    if (this.props.endTime && this.props.saleMode !== 'subscription_fission') {
      if (new Date(this.props.endTime) > new Date(this.props.systemTime)) {
        inviteButtonText = '继续邀请';
      }
    } else if (this.props.saleMode == "invite") {
        if (this.props.invitedUsersCount <= 0) {
          inviteButtonText = '开始邀请';
        } else if (
          this.props.invitedUsersCount < this.props.inviteCountRequired
        ) {
          inviteButtonText = '继续邀请';
        }
    } else if (this.props.saleMode == "subscription_fission") {
      switch (this.props.inviteStatus) {
        case 'not_started':
          inviteButtonText = '开始邀请';
          break;
        case 'inprogress':
          inviteButtonText = '继续邀请';
          break;
        case 'end':
          if (this.props.bought !== true) {
            inviteButtonText = '邀请超时';
          }
          break;
      }
    } else {
        inviteButtonText = '开始邀请';
    }
    let inviteButton;
    if (inviteButtonText) {
        inviteButton = (
          <div className="invite_user" onClick={this.subInviteUser}>
            {inviteButtonText}
          </div>
        );
    }

    let staticSelectMaxMoney =
      this.props.selectMaxMoney > 0
        ? this.props.selectMaxMoney
        : this.state.selectMaxMoney;
    console.log("staticSelectMaxMoney: ", staticSelectMaxMoney);

    let aloneSignMoney;
    if (this.state.selectIndex == -1) {
      aloneSignMoney = "";
    } else {
      const owned_lesson_count = this.props.subStudyPackageActivities[
        this.state.selectIndex
      ].owned_media_lesson_count;

      if (owned_lesson_count > 0) {
        // 已有课时按比例折扣
        const hasOwnedOrBargainPrice =
          this.calcDiscountPercentPrice(staticSelectMaxMoney) -
          canUsedHighestPrice;
        aloneSignMoney =
          hasOwnedOrBargainPrice > 0 ? hasOwnedOrBargainPrice : 0;
      } else {
        const noOwnedOrBargainPrice =
          staticSelectMaxMoney - canUsedHighestPrice;
        aloneSignMoney = noOwnedOrBargainPrice > 0 ? noOwnedOrBargainPrice : 0;
      }
      console.log("aloneSignMoney: ", aloneSignMoney);
    }

    const singleAlone =
      this.state.activity &&
      this.state.activity.sale_mode !== "shopping_group" &&
      (this.state.activity.activity_type ===
        StudyPackageActivity.activityType.group ||
        this.state.activity.sale_mode == "normal" ||
        discountPercentPrice == 0);
    console.log("singleAlone: ", singleAlone);

    const subStudyPackageActivitiesLength = this.props.subStudyPackageActivities
      .length;

    let calcDiscountSubtractPrice =
      this.calcDiscountPercentPrice(
        this.props.selectMaxMoney > 0
          ? this.props.selectMaxMoney
          : this.state.selectMaxMoney
      ) - (singleAlone ? 0 : canUsedHighestPrice);
    console.log("calcDiscountSubtractPrice: ", calcDiscountSubtractPrice);
    // 注：课程连报不支持购买砍价

    const discountPercentPrice =
      calcDiscountSubtractPrice > 0 ? calcDiscountSubtractPrice : 0;
    console.log(
      "discountPercentPrice, propsSelectMaxMoney, stateSelectMaxMoney",
      discountPercentPrice,
      this.props.selectMaxMoney,
      this.state.selectMaxMoney
    );

    // 判断是否是一元学一周
    const judgeIsOneCoinLearningOneWeek =
      this.props.isThinkingSeries && this.state.isOneCoinLearningOneWeek;

    const renderCloseList = () => {
      return (
        <div
          className="close_btn_right"
          style={{ top: -4 }}
          onClick={this.btnCloseRecommendListClick}
        >
          &times;
        </div>
      );
    };

    const renderCourseListContainerUl = () => {
      return (
        <ul id="course_list_container_ul">
          {this.props.subStudyPackageActivities &&
            this.props.subStudyPackageActivities
              .filter((act) => {
                if (!this.state.isOneCoinLearningOneWeek) {
                  return act;
                }
                return act.activity_type === 0;
              })
              .map((activity, index) => {
                const notHighLightStatus =
                  activity.state == "pre_has_bought" ||
                  activity.state == "superset" ||
                  activity.state == "bought" ||
                  (this.state.isOneCoinLearningOneWeek &&
                    activity.trial_order_state === "bought");

                // 涉及到上层传过来的数据
                let parentIndexMatchCorrect = false;
                if (!this.state.preventCallPropsSetState)
                  parentIndexMatchCorrect =
                    this.props.selectIndexCourse > 0
                      ? this.props.selectIndexCourse == index
                      : this.state.selectIndex == index;

                // 当前组件处理点击的数据
                const currentIndexMatchCorrect =
                  this.state.selectIndex == index;

                // const lastIndexMatchCorrect = this.props.isExperienceCourseStatus
                //   ? currentIndexMatchCorrect
                //   : parentIndexMatchCorrect;

                let currentHighLightClass;
                if (notHighLightStatus) {
                  currentHighLightClass = null;
                } else {
                  if (currentIndexMatchCorrect || parentIndexMatchCorrect) {
                    currentHighLightClass = "highLight";
                  } else {
                    currentHighLightClass = null;
                  }
                }
                // if(activity.redirect_url){
                //   window.location.href=activity.redirect_url
                // }

                // let subCourseType=
                return (
                  <li
                    key={activity.id}
                    onClick={
                      activity.state == "pre_has_bought" ||
                      activity.state == "superset" ||
                      activity.state == "bought" ||
                      (this.state.isOneCoinLearningOneWeek &&
                        activity.trial_order_state === "bought")
                        ? null
                        : activity.remaining_count == 0
                        ? null
                        : this.selectClick.bind(this, activity)
                    }
                    data-recommend={activity.is_recommended}
                    data-index={index}
                    data-id={activity.id}
                    data-state={activity.state}
                    data-list-value={activity.list_price}
                    data-max-value={activity.max_price}
                    data-min-value={activity.min_price}
                    data-launcher-value={activity.launcher_price}
                    data-name={activity.name}
                    className={currentHighLightClass}
                  >
                    {this.renderContent(
                      activity,
                      inviteRewardPrice,
                      index,
                      subStudyPackageActivitiesLength,
                      this.props.subCourseType
                    )}
                    {/* <a href="javascript:void(0)">
                      {
                        this.renderContentTop(activity, inviteRewardPrice)
                      }
                      {
                        this.renderContentBottom(activity)
                      }
                      </a> */}
                  </li>
                );
              })}
          {this.state.selectIndex > -1 && (
            <div className="remind_text">
              {this.props.saleMode == "bargain" && (
                <CountDownTime
                  endTime={new Date(this.props.buyEndtime)}
                  overText=""
                  onEnd={this.handleCountdownEnd}
                >
                  {({ d, h, m, s }) => {
                    return (new Date(this.props.buyEndtime).getTime() -
                      new Date(this.props.systemTime).getTime()) /
                      1000 /
                      60 /
                      60 >
                      this.props.bargainDurationHours ? (
                      <span
                        style={{
                          color: "#a8a8a8",
                          fontSize: "13px",
                        }}
                      >
                        {this.props.bargainDurationHours}
                        小时内，每邀请1位新朋友关注『心芽学堂』公众号可减
                        {inviteRewardPrice}元，最高可
                        <span style={{ color: "#e86d74" }}>
                          减至
                          {this.props.bargainActivity?.lowest_price}
                          元购买
                        </span>
                        ！（优惠仅可用于其中1个课程）
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "#a8a8a8",
                          fontSize: "13px",
                        }}
                      >
                        {~~h}
                        小时内，每邀请1位新朋友关注『心芽学堂』公众号可减
                        {inviteRewardPrice}元，最高可
                        <span style={{ color: "#e86d74" }}>
                          减至
                          {this.props.bargainActivity?.lowest_price}
                          元购买
                        </span>
                        ！（优惠仅可用于其中1个课程）
                      </span>
                    );
                  }}
                </CountDownTime>
              )}
            </div>
          )}
        </ul>
      );
    };

    const renderNormalMode = () => {
      return (
        this.props.saleMode == "normal" &&
        (this.judgeBtnBottomIsHide() ? (
          ""
        ) : (
          <div
            className="btn_buttom"
            onClick={
              this.state.selectActivityRedirect
                ? () => {
                    return;
                  }
                : this.btnPayRequest.bind(
                    this,
                    this.calcDiscountPercentPrice(
                      this.props.selectMaxMoney > 0
                        ? this.props.selectMaxMoney
                        : this.state.selectMaxMoney
                    ),
                    this.props.selectActivityId
                      ? this.props.selectActivityId
                      : this.state.selectActivityId,
                    this.state.selectName,
                    this.state.selectIndex
                  )
            }
          >
            <div
              className="normal_mode"
              style={{
                backgroundColor: !this.state.activeHighLight ? "#c5c5c5" : "",
              }}
            >
              {discountPercentPrice ? (
                <p>
                  {`¥${discountPercentPrice}`}
                  {this.findOwnedMediaLessonCount() && (
                    <del>
                      {`¥${
                        this.props.selectMaxMoney > 0
                          ? this.props.selectMaxMoney
                          : this.state.selectMaxMoney
                      }`}
                    </del>
                  )}
                </p>
              ) : (
                ""
              )}
              <p
                style={{
                  fontSize: !discountPercentPrice ? "18px" : "",
                }}
              >
                {discountPercentPrice == 0 && this.state.activeHighLight
                  ? "免费领取"
                  : "立即报名"}
              </p>
            </div>
          </div>
        ))
      );
    };

    const renderShoppingGroupMode = () => {
      return (
        (this.props.saleMode == "shopping_group" ||
          this.props.saleMode == "shopping_group_new_member_only") &&
        (this.judgeBtnBottomIsHide() ? (
          ""
        ) : (
          <div className="btn_buttom">
            {discountPercentPrice != 0 && (
              <div
                className="group_sign_price"
                style={{
                  display: singleAlone ? "none" : "block",
                  backgroundColor: !this.state.activeHighLight ? "#d8d8d8" : "",
                }}
                onClick={
                  this.state.selectActivityRedirect
                    ? () => {
                        return;
                      }
                    : this.groupBuyClick.bind(
                        this,
                        this.state.selectIndex,
                        this.state.activity
                      )
                }
              >
                {this.groupPrice()}
                {this.grouponModeStatus()}
              </div>
            )}
            <div
              className={
                singleAlone ? "alone_sign_price single" : "alone_sign_price"
              }
              onClick={
                this.state.selectActivityRedirect
                  ? () => {
                      return;
                    }
                  : this.aloneSignClick.bind(
                      this,
                      aloneSignMoney,

                      this.state.preventCallPropsSetState
                        ? Number(this.state.selectActivityId)
                        : this.props.selectActivityId
                        ? this.props.selectActivityId
                        : this.state.selectActivityId,
                      this.state.selectName,
                      this.state.selectIndex
                    )
              }
              style={{
                display: this.props.grouponDirectBuy === true ? "none" : null,
                backgroundColor: !this.state.activeHighLight ? "#c5c5c5" : "",
              }}
            >
              {discountPercentPrice == 0 ? (
                !this.state.activeHighLight ? (
                  <div style={{ lineHeight: "53px" }}>选择课程</div>
                ) : (
                  <div style={{ lineHeight: "53px" }}>免费领取</div>
                )
              ) : (
                <>
                  <p>
                    {`¥${discountPercentPrice}`}
                    {this.findOwnedMediaLessonCount() && (
                      <del>
                        {`¥${
                          this.props.selectMaxMoney > 0
                            ? this.props.selectMaxMoney
                            : this.state.selectMaxMoney
                        }`}
                      </del>
                    )}
                  </p>
                  <p>单独购买</p>
                </>
              )}
            </div>
          </div>
        ))
      );
    };
    const renderBargainInviteRelativeMode = () => {
      return (
        (this.props.saleMode == "bargain" ||
          this.props.saleMode == "invite" ||
          this.props.saleMode == "subscription_fission" ||
          this.props.saleMode == "buy_bargain") &&
        (this.judgeBtnBottomIsHide() ? (
          ""
        ) : (
          /**
           * 判断是”继续邀请“原因有2个：
           * 1. 砍价模式下， 存在endTime
           * 2. 邀请模式下， 并且被邀请的人数数量小于后台设定的最大的邀请人数
           */
          <div className="btn_buttom">
            {inviteButton}
            <div
              style={{
                width:
                  new Date(this.props.endTime) <
                    new Date(this.props.systemTime) && this.props.endTime
                    ? "100%"
                    : null,
                backgroundColor: !this.state.activeHighLight ? "#d8d8d8" : "",
              }}
              className={`bargain_sign ${
                this.props.saleMode == "invite" &&
                this.props.invitedUsersCount >= this.props.inviteCountRequired
                  ? "bargain_sign_free"
                  : ""
              }`}
              onClick={
                this.state.selectActivityRedirect
                  ? () => {
                      return;
                    }
                  : this.subBargainSignClick.bind(
                      this,
                      this.calcDiscountPercentPrice(
                        this.props.selectMinMoney > 0
                          ? this.props.selectMinMoney
                          : this.state.selectMinMoney
                      ),
                      this.calcDiscountPercentPrice(
                        this.props.selectMaxMoney > 0
                          ? this.props.selectMaxMoney
                          : this.state.selectMaxMoney
                      ),
                      this.props.selectActivityId
                        ? this.props.selectActivityId
                        : this.state.selectActivityId,
                      this.state.selectName,
                      this.state.selectIndex
                    )
              }
            >
              {this.state.activeHighLight && finallyPayPrice}
              {finallySignText}
            </div>
          </div>
        ))
      );
    };

    const NonActiveHighLight = () => {
      return (
        <div className="btn_buttom">
          <div
            className="alone_sign_price single"
            onClick={
              this.state.selectActivityRedirect
                ? () => {
                    return;
                  }
                : this.groupBuyClick.bind(
                    this,
                    this.state.selectIndex,
                    this.state.activity
                  )
            }
            style={{
              backgroundColor: "#c5c5c5",
            }}
          >
            <div style={{ lineHeight: "53px" }}>立即报名</div>
          </div>
        </div>
      );
    };

    const renderTrialExperience = () => {
      let max_price =
        this.state.activity.stage == 4
          ? this.state.activity.max_price
          : discountPercentPrice;
      console.log("max_price: ", max_price);
      return (
        <div className="btn_buttom">
          <div
            className="alone_sign_price single"
            onClick={
              this.state.selectActivityRedirect
                ? () => {
                    return;
                  }
                : this.aloneSignClick.bind(
                    this,
                    max_price,
                    this.props.selectActivityId
                      ? this.props.selectActivityId
                      : this.state.selectActivityId,
                    this.state.selectName,
                    this.state.selectIndex
                  )
            }
          >
            <p>{`¥${max_price}`}</p>
            <p>立即报名</p>
          </div>
        </div>
      );
    };

    const renderBeforeInviteButton = () => {
      if (!this.props.shoppingBargain) {
        return "";
      }
      const { max_bargain_amount } = this.props.shoppingBargain;

      if (!this.state.activeHighLight) {
        return NonActiveHighLight();
      } else if (singleAlone) {
        return renderTrialExperience();
      } else {
        return (
          <div className="btn_buttom">
            <div
              className="new_shopping_discount_price dividing_line"
              onClick={
                this.state.selectActivityRedirect
                  ? () => {
                      return;
                    }
                  : this.groupBuyClick.bind(
                      this,
                      this.state.selectIndex,
                      this.state.activity
                    )
              }
            >
              {this.grouponModeStatus()}
              {this.groupPrice()}
            </div>
            <div
              className="invite_new_friend"
              onClick={this.props.inviteFriendPoster}
            >
              <p>邀请砍价</p>
              <p>
                最低可至￥
                {aloneSignMoney - max_bargain_amount <= 0
                  ? 0
                  : aloneSignMoney - max_bargain_amount}
              </p>
            </div>
            <div
              className="right_blue_price"
              onClick={
                this.state.selectActivityRedirect
                  ? () => {
                      return;
                    }
                  : this.aloneSignClick.bind(
                      this,
                      aloneSignMoney,
                      this.props.selectActivityId
                        ? this.props.selectActivityId
                        : this.state.selectActivityId,
                      this.state.selectName,
                      this.state.selectIndex
                    )
              }
            >
              <p>直接报名</p>
              <p>{`¥${discountPercentPrice}`}</p>
            </div>
          </div>
        );
      }
    };

    const renderAfterInviteButton = () => {
      if (!this.props.shoppingBargain) {
        return "";
      }
      const {
        invited_users_count,
        max_bargain_amount,
      } = this.props?.shoppingBargain;

      // 还可以砍”xxx“元 计算公式：(原价 - 最低价) - (已邀请的人数 * 邀请一个人的价格)
      const inviteDiscountPrice =
        canUsedHighestPrice > 0 ? `(已减${canUsedHighestPrice}元)` : "";

      const isEndTimeOutOver =
        new Date(this.props.shoppingBargainEndTime) <
        new Date(this.props.systemTime);
      console.log("isEndTimeOutOver: ", isEndTimeOutOver);

      if (!this.state.activeHighLight) {
        return NonActiveHighLight();
      } else if (singleAlone) {
        return renderTrialExperience();
      } else {
        return (
          <React.Fragment>
            <DiscountCountDown
              text={
                // 砍价倒计时时间结束
                isEndTimeOutOver
                  ? `成功邀请${invited_users_count}人`
                  : `${
                      lastRemaingBargainPrice == 0
                        ? `成功邀请${invited_users_count}人`
                        : `还可继续砍${
                            max_bargain_amount - shoppingBargainDiscountPrice
                          }元`
                    }`
              }
              bargainEndTime={this.props.shoppingBargainEndTime}
              warnText="砍价"
            />
            <div className="btn_buttom">
              {
                // 邀请人数已满，已折扣最大、时间结束
                lastRemaingBargainPrice == 0 || isEndTimeOutOver ? (
                  ""
                ) : (
                  <div
                    className="invite_new_friend dividing_line"
                    onClick={this.props.inviteFriendPoster}
                  >
                    <p>继续邀请</p>
                    <p>{`已成功邀请${invited_users_count}人`}</p>
                  </div>
                )
              }
              <div
                className="new_shopping_discount_price"
                onClick={
                  this.state.selectActivityRedirect
                    ? () => {
                        return;
                      }
                    : this.aloneSignClick.bind(
                        this,
                        aloneSignMoney,
                        this.props.selectActivityId
                          ? this.props.selectActivityId
                          : this.state.selectActivityId,
                        this.state.selectName,
                        this.state.selectIndex
                      )
                }
              >
                <p>{discountPercentPrice <= 0 ? "免费领取" : "立即报名"}</p>
                <p>{`¥${discountPercentPrice} ${inviteDiscountPrice}`}</p>
              </div>
              <div
                className="right_blue_price"
                onClick={
                  this.state.selectActivityRedirect
                    ? () => {
                        return;
                      }
                    : this.groupBuyClick.bind(
                        this,
                        this.state.selectIndex,
                        this.state.activity,
                        true
                      )
                }
              >
                直接拼团
                {this.groupPrice()}
              </div>
            </div>
          </React.Fragment>
        );
      }
    };

    const renderOneCoinLearningOneWeek = () => {
      return (
        <div className="btn_buttom">
          <div
            className="normal_mode"
            style={{ height: 64 }}
            onClick={() =>
              this.props.qrcodeUrl != null
                ? this.onWechatFollow()
                : this.btnPayRequest(
                    // 一元学一周 支付一元
                    1,
                    this.props.selectActivityId
                      ? this.props.selectActivityId
                      : this.state.selectActivityId,
                    `《${this.state.selectName}》1元学一周`,
                    this.state.selectIndex,
                    true
                  )
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                lineHeight: "20px",
              }}
            >
              <p
                className="top"
                style={
                  !!this.props.batchEcouponsAmount
                    ? {}
                    : { fontSize: 18, lineHeight: "1em" }
                }
              >
                <span className="strong_num" style={{ fontSize: 26 }}>
                  1
                </span>
                元学一周
              </p>
              {!!this.props.batchEcouponsAmount && (
                <p className="bottom">
                  还可得
                  <span className="strong_num">
                    ￥{this.props.batchEcouponsAmount}
                  </span>
                  课程优惠券
                </p>
              )}
            </div>
          </div>
        </div>
      );
    };

    // "立即报名" + "赠课优惠"
    const renderPresentCourseDiscount = () => {
      return (
        <div className="btn_buttom">
          <div
            className={
              singleAlone ? "alone_sign_price single" : "alone_sign_price"
            }
            onClick={
              this.state.selectActivityRedirect
                ? () => {
                    return;
                  }
                : this.aloneSignClick.bind(
                    this,
                    aloneSignMoney,
                    this.props.selectActivityId
                      ? this.props.selectActivityId
                      : this.state.selectActivityId,
                    this.state.selectName,
                    this.state.selectIndex
                  )
            }
            style={{
              backgroundColor: singleAlone ? "#e86d74" : "#f09398",
            }}
          >
            {discountPercentPrice == 0 ? (
              <div style={{ lineHeight: "53px" }}>立即报名</div>
            ) : (
              <>
                <p>
                  {`¥${discountPercentPrice}`}
                  {this.findOwnedMediaLessonCount() && (
                    <del>
                      {`¥${
                        this.props.selectMaxMoney > 0
                          ? this.props.selectMaxMoney
                          : this.state.selectMaxMoney
                      }`}
                    </del>
                  )}
                </p>
                <p>立即报名</p>
              </>
            )}
          </div>
          {discountPercentPrice != 0 && !singleAlone && (
            <div
              className="group_sign_price"
              style={{
                backgroundColor: "#e86d74",
              }}
              onClick={
                this.state.selectActivityRedirect
                  ? () => {
                      return;
                    }
                  : this.presentCourseDiscountDialog.bind(
                      this,
                      discountPercentPrice,
                      "new_groupon",
                      this.props.selectActivityId
                        ? this.props.selectActivityId
                        : this.state.selectActivityId,
                      `《${this.state.selectName}》<span style="color: #fb636f">预付金</span>`,
                      "group",
                      this.state.selectIndex,
                      false
                    )
              }
            >
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {`¥${parseInt(discountPercentPrice * 0.8)}`}
                <span>&nbsp;起</span>
              </p>
              <p>赠课优惠</p>
            </div>
          )}
        </div>
      );
    };

    let bottomPriceButtonModule = "";
    if (judgeIsOneCoinLearningOneWeek) {
      // 1元学一周
      bottomPriceButtonModule = renderOneCoinLearningOneWeek();
    } else {
      if (this.props.saleMode == "invitation_fission") {
        // 立即报名 + 赠课优惠
        bottomPriceButtonModule = renderPresentCourseDiscount();
      } else if (this.props.saleMode == "shopping_group_bargain") {
        bottomPriceButtonModule = (
          // 购买模式是：拼团砍价
          <React.Fragment>
            {!this.props.shoppingBargainEndTime
              ? // 课程选择界面组件 · 触发”邀请“按钮之前
                renderBeforeInviteButton()
              : // 课程选择界面组件 · 触发”邀请“按钮之后
                renderAfterInviteButton()}
          </React.Fragment>
        );
      } else {
        bottomPriceButtonModule = (
          <React.Fragment>
            {
              // 普通模式
              renderNormalMode()
            }
            {
              // 团购模式
              renderShoppingGroupMode()
            }
            {
              // 砍价相关模式
              renderBargainInviteRelativeMode()
            }
          </React.Fragment>
        );
      }
    }
    return (
      <React.Fragment>
        <div
          className="sub_activity_choose_list"
          style={{ display: this.state.isChooseDisplay }}
        >
          <div className="course_list_container">
            <h3>请选择想要学习的课程</h3>
            {/* {judgeIsOneCoinLearningOneWeek && (
              <div className="trial_study_text">
                {formatDotDate(this.props.trialStartDate)}
                <span>周二</span>开课，开课后<span>7天</span>内有效
              </div>
            )} */}
            {this.props.isShowStartTime == false ? (
              <p>开课日期，在购买成功后设定</p>
            ) : (
              ""
            )}
            {
              // 关闭弹框
              renderCloseList()
            }
            {
              // 选项列表
              renderCourseListContainerUl()
            }
            {bottomPriceButtonModule}
          </div>
          <WechatFollow
            ref={(ref) => (this.wechatFollowRef = ref)}
            qrcodeUrl={this.props.qrcodeUrl}
          />
        </div>
      </React.Fragment>
    );
  }
}
