/* eslint-disable react/prop-types */
import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  memo,
} from "react";
import ErrorBoundary from "../utils/ErrorBoundary";
import { sortECouponsOnlyForCouponUseMode } from "./CourseCoupons";
import { TicketPriceCondition } from "../batch_ecoupons/CouponGift.jsx";
import { findDefaultSelectedTicket } from "./ThinkingDetails";
import styled from "styled-components";
import icon_select from "coupon/icon_select.png";
import icon_selected from "coupon/icon_selected.png";
import coupon_empty from "coupon/coupon_empty.png";
import icon_share_24 from "coupon/icon_share_24.png";
import arrow_left from "arrow-left";

export const StyledThinkingCoupons = styled.div`
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
  }
  .overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.65);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
  .has_ticket_top {
    background: #fff;
    position: relative;
    .dialog_title {
      text-align: center;
      padding: 10px 0;
      color: #666;
    }
    .close_btn_right {
      width: 20px;
      height: 26px;
      position: absolute;
      top: 5px;
      left: 0;
      background: url(${arrow_left}) no-repeat;
      background-size: 8px;
      background-position: 10px 11px;
    }
  }
  ul {
    background: #fff;
    padding: 10px 0;
    li {
      margin: 15px;
      .coupon_ticket {
        margin: 0 auto;
        box-shadow: 0px 0px 5px 0px rgba(0, 1, 0, 0.15);
        border-radius: 8px;
        display: flex;
        flex-wrap: nowrap;
        .ticket_left {
          border-radius: 8px 0 0 8px;
          flex: 1;
          background-color: #fb636f;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background-color: #fff;
            top: -6.5px;
            right: -6.5px;
            z-index: 999999;
          }
          &::after {
            content: "";
            position: absolute;
            width: 13px;
            height: 13px;
            border-radius: 50%;
            background-color: #fff;
            bottom: -6.5px;
            right: -6.5px;
            z-index: 999999;
          }
        }
        .ticket_right {
          background-repeat: no-repeat;
          background-size: 16px;
          background-position: right 10px center;
          background-color: #fff;
          border-radius: 0 8px 8px 0;
          flex: 2;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 15px 10px;
          position: relative;
          .ticket_list {
            .ticket_desc {
              font-size: 12px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .text {
                color: #333333;
                line-height: 1em;
              }
              .text_over_available {
                font-size: 14px;
                font-weight: 500;
              }
              .present_coupon {
                position: absolute;
                right: 0;
                color: #837623;
                border-radius: 4px 0 0 4px;
                padding: 2px 5px 2px 20px;
                background: #fdf3ba url(${icon_share_24}) no-repeat;
                background-position: 5px 5px;
                background-size: 12px;
              }
            }
            .ticket_name {
              margin-top: 10px;
              font-size: 14px;
              color: #333333;
              font-weight: 600;
              line-height: 1em;
              p {
                padding-bottom: 10px;
              }
            }
          }
          .ticket_discount_status {
            &::after {
              content: "";
              display: block;
              clear: both;
            }
            .date {
              font-size: 12px;
              color: #333333;
              line-height: 1em;
              padding-top: 3px;
              float: left;
            }
            .consume_status {
              .todo_consume {
                font-size: 12px;
                color: #fb636f;
              }
            }
          }
        }
        .un_use_ticket {
          font-weight: 500;
        }
        .icon_un_use {
        }
      }
    }
  }
  .coupon_info {
    text-align: center;
    padding-bottom: 20px;
    img {
      display: block;
      width: 185.5px;
      margin: 0 auto 0;
      padding: 20px 0;
    }
    span {
      color: #888;
      font-size: 14px;
    }
  }
`;

const COURSE_PAY_MODE = [
  // 通用 不显示
  { inner_enum: [], name: "" },
  { inner_enum: ["join_groupon", "group"], name: "仅限团购模式" },
  { inner_enum: ["direct"], name: "仅限直接购买模式" },
];

const getCouponUseState = (
  coupon,
  systemTime,
  coursePayMode,
  coursePayFinalPrice
) => {
  let tipText = "";
  // 'remaining'
  if (
    coursePayMode &&
    coupon.use_mode != null &&
    !COURSE_PAY_MODE[coupon.use_mode]?.inner_enum.includes(coursePayMode)
  ) {
    tipText = COURSE_PAY_MODE[coupon.use_mode]?.name;
    tipText = tipText ? `（${tipText}）` : "";
  }

  if (new Date(coupon.start_time) > new Date(systemTime)) {
    tipText = "（使用时间未到）";
  }
  return {
    isCanUse: !(
      coursePayFinalPrice < coupon.min_product_price ||
      tipText ||
      coupon.valid === false
    ),
    tipText,
  };
};

const CouponCanNotUseTip = ({ tipText }) => {
  return tipText ? (
    <div className="consume_status">
      <div className="todo_consume">{tipText}</div>
    </div>
  ) : (
    ""
  );
};

const ThinkingCoupons = ({ ...props }) => {
  console.log("优惠券列表 ThinkingCoupon_props: ", props);

  const defaultTicket = findDefaultSelectedTicket(props);
  console.log("默认优惠券-defaultTicket: ", defaultTicket);
  const [state, setState] = useState({
    selectCouponId: defaultTicket.defaultSelectCouponId,
    selectIndex:
      props.collectCouponData.index >= 0
        ? props.collectCouponData.index
        : defaultTicket.selectIndex ?? -1,
    selectCouponValue: defaultTicket.defaultSelectPrice,
  });
  const { eCouponData, collectCouponData } = props;
  console.log("--collectCouponData: ", collectCouponData);

  // 此事件是点击后获取优惠券的值
  const selectCorrespondTicketClick = (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    const index = e.currentTarget.getAttribute("data-index");
    const coupon_value = e.currentTarget.getAttribute("data-value");

    setState({
      selectCouponId: id,
      selectIndex: index,
      selectCouponValue: coupon_value,
    });
    props.setCloseCouponDialog(false);
    props.setCollectCouponData({ id, index, coupon_value });
  };
  // 有优惠券回退关闭按钮
  const btnCloseTicketSelectClick = () => {
    props.setCloseCouponDialog(false);
  };

  return (
    props.closeCouponDialog && (
      <StyledThinkingCoupons>
        {/* 有优惠券弹框选券 */}
        <div className="overlay"></div>
        <div className="has_ticket_top">
          <div className="dialog_title">选择优惠券</div>
          <div
            className="close_btn_right"
            onClick={btnCloseTicketSelectClick}
          ></div>
        </div>
        {eCouponData && eCouponData.length > 0 ? (
          <ul>
            {eCouponData.map((ecoupon, index) => {
              const { isCanUse, tipText } = getCouponUseState(
                ecoupon,
                props.system_time,
                "direct",
                props.coursePayFinalPrice
              );
              return (
                <li
                  key={index}
                  onClick={!isCanUse ? null : selectCorrespondTicketClick}
                  data-id={ecoupon.id}
                  data-index={index}
                  data-value={ecoupon.price}
                  data-iscanuse={isCanUse}
                >
                  <div className="coupon_ticket">
                    <div
                      className="ticket_left"
                      style={{
                        backgroundColor: !isCanUse ? "#ddd" : null,
                      }}
                    >
                      <TicketPriceCondition
                        price={ecoupon.price}
                        minProductPrice={ecoupon.min_product_price}
                      />
                    </div>
                    <div
                      className="ticket_right"
                      style={{
                        backgroundImage: !isCanUse
                          ? null
                          : state.selectIndex == index
                          ? `url(${icon_selected})`
                          : `url(${icon_select})`,
                        // state.selectIndex == index
                        //   ? `url(${icon_selected})`
                        //   : !isCanUse
                        //   ? null
                        //   : `url(${icon_select})`,
                      }}
                    >
                      <div className="ticket_list">
                        <div className="ticket_desc">
                          <div
                            className={
                              ecoupon.all_available === true
                                ? "text_over_available"
                                : "text"
                            }
                          >
                            {ecoupon.title}
                          </div>
                        </div>
                        <div className="ticket_name">
                          {ecoupon.course_availables.courses.map((course) => (
                            <p key={course.id}>{course.name}</p>
                          ))}
                        </div>
                      </div>

                      <div className="ticket_discount_status">
                        <div className="date">
                          {(ecoupon.start_time.replace(/-/g, ".") + "").substr(
                            0,
                            10
                          )}{" "}
                          -{" "}
                          {(ecoupon.end_time.replace(/-/g, ".") + "").substr(
                            0,
                            10
                          )}
                        </div>
                        <CouponCanNotUseTip tipText={tipText} />
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
            <li
              onClick={selectCorrespondTicketClick}
              data-id="-1"
              data-index={eCouponData.length}
              data-value="0"
            >
              <div
                className="coupon_ticket"
                style={{
                  backgroundImage:
                    collectCouponData.index == eCouponData.length
                      ? `url(${icon_selected})`
                      : `url(${icon_select})`,
                  position: "relative",
                  padding: "10px",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "16px",
                  backgroundPosition: "right 10px center",
                }}
              >
                <div
                  className="icon_un_use"
                  style={{
                    position: "absolute",
                    right: "10px",
                  }}
                ></div>
                <div className="un_use_ticket">不使用优惠券</div>
              </div>
            </li>
          </ul>
        ) : (
          <div className="coupon_info">
            <img src={coupon_empty} alt="" />
            <span>您还没有优惠券</span>
          </div>
        )}
      </StyledThinkingCoupons>
    )
  );
};

export default ThinkingCoupons;
